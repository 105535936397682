.actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.abierta {
  background-color: var(--color-primary-green);
  color: white;
  padding: 3px 5px;
  margin: 0 2px;
  border-radius: 4px;
}

.descuadre {
  font-weight: bold;
}

.descuadre[data-tipo='positivo'] {
  color: var(--color-primary-green);
}

.descuadre[data-tipo='negativo'] {
  color: var(--color-primary-red);
}