.main {
  background: linear-gradient(135deg, #fff, var(--color-primary-green-light));
  min-height: 100%;
}

.content {
  margin: 2rem;
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.card {
  margin: 2rem auto;
}

.video {
  width: 320px;
  height: 240px;
  display: block;
  margin: auto;
}

.video[data-visible="hidden"] {
  display: none;
}

.image {
  width: 320px;
  height: 240px;
  display: block;
  margin: auto;
}

.image[data-visible="hidden"] {
  display: none;
}

.actions {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 15px 15px;
}
