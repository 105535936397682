.label {
  display: flex;
  align-items: center;
}

.title {
  font-size: 1.2rem;
  font-weight: 500;
}

.button {
  margin-left: 10px;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px 20px;
}

.password {
  align-self: flex-start;
  margin-left: -10px;
}

.email {
  margin-left: 10px;
}