.label {
  display: flex;
  align-items: center;
}

.title {
  font-size: 1.2rem;
  font-weight: 500;
}

.button {
  margin-left: 10px;
}

.row {
  display: flex;
}

.field {
  margin: 10px 20px;
  flex-grow: 1;
}

.fieldTitle {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1em;
  letter-spacing: 0.00938em;
  margin: 10px 0 20px;
}

@media(max-width: 1200px) {
  .row {
    flex-direction: column;
  }
  .field {
    margin: 10px 0 25px;
  }
}