.tabs {
  margin-top: 20px;
}

.actions {
  display: flex;
  align-items: flex-end;
} 

.mes {
  margin-left: 40px;
}

.link {
  color: var(--color-dark);
  text-decoration: none;
}

.link:hover {
  color: var(--color-primary-green);
  cursor: pointer;
}