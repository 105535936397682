.header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 1rem;
}

.error {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12rem 0;
}

.errorMessage {
  font-size: 1.25rem;
  font-style: italic;
  color: var(--color-gray);
}

.fechaSelector {
  max-width: 300px;
}

.tabs {
  margin-top: 1rem;
}

.tabLabelDetalles {
  margin-top: 6px;
}

.tabLabelElement {
  display: inline-block;
  margin: 0 4px;
  padding: 2px 7px;
  border-radius: 50%;
}

.tabLabelElement[data-tipo="CIENCIAS"] {
  background: var(--color-auxiliary);
}

.tabLabelElement[data-tipo="LETRAS"] {
  background: var(--color-primary-orange);
}

.tabLabelElement[data-tipo="IDIOMAS"] {
  background: var(--color-secondary-yellow);
}

.tabLabelElement[data-tipo="OTRAS"] {
  background: var(--color-gray);
}

.tabLabelElement[data-tipo="FORMACIONES"] {
  background: var(--color-clase-recuperacion);
}

.tabContent {
  margin-top: 2rem;
}

.cell[data-visibility='invisible'] {
  display: none;
}