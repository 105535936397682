.label {
  display: flex;
  align-items: center;
}

.title {
  font-size: 1.2rem;
  font-weight: 500;
}

.content {
  padding-left: 40px;
}

.placeholder {
  margin: 0 2rem;
  color: var(--color-gray);
  font-style: italic;
}