.main {
  min-height: 100%;
}

.spinner {
  margin-top: 150px;
}

.title {
  margin: 1rem 1.5rem;
}

.content {
  margin-bottom: 100px;
}

.placeholder {
  margin: 0 2rem;
  color: var(--color-gray);
  font-style: italic;
}

.nuevo {
  position: fixed;
  bottom: 20px;
  right: 20px;
}
