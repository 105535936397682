.menu {
  background-color: var(--color-dark);
  width: var(--width-sidebar);
  position: fixed;
  left: 0;
  top: 50px;
  bottom: 0;
  z-index: 2;
  transition: width .1s;

}

.navigation {
  margin-top: 10px;
  max-height: calc(100vh - 205px);
  overflow-y: auto;
}

.bottom {
  position: absolute;
  bottom: 0;
}

[data-collapse='collapsed'] .bottom {
  display: none;
}

.element {
  list-style: none;
}

.elementLink {
  display: block;
  color: #838ea4;
  font-size: 15px;
  height: 50px;
  line-height: 21px;
  max-width: 100%;
  padding: 16px 10px 16px 12px;
  text-decoration: none;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s;
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.elementLink[data-status="active"] {
  color: #e6e8ed;
}

.elementLink:hover {
  color: #ced2da;
}

.elementContent {
  margin: 0;
  display: flex;
  align-items: center;
}

.elementIcon {
  font-size: 25px;
  margin-right: 12px;
}

.elementText {
  font-size: 16px;
}

.elementList {
  height: 0;
  line-height: 35px;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding-left: 15px;
}

.elementList[data-status="active"] {
  height: auto;
}

.elementList .elementText {
  font-size: 14px;
}

.elementList .elementIcon {
  font-size: 20px;
  margin-right: 10px;
}

.elementList .elementList .elementText {
  font-size: 12px;
}

.elementList .elementList .elementIcon {
  font-size: 18px;
}
