.label {
  display: flex;
  align-items: center;
}

.title {
  font-size: 1.2rem;
  font-weight: 500;
}

.alert {
  margin-bottom: 1rem;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.field {
  margin: 10px 20px;
  flex-grow: 1;
}

.button {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}
