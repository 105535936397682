.actions {
  display: flex;
  align-items: flex-end;
}

.crm {
  display: flex;
  width: 100%;
  min-width: 1000px;
  overflow: auto;
  justify-content: space-evenly;
  margin-bottom: 40px;
}

.column {
  flex-basis: calc(25% - 10px);
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 8px;
  min-height: 80vh;
}

.column[data-status="active"] {
  color: var(--color-primary-green-light);
  border: 6px dashed var(--color-primary-green);
  background-color: rgba(var(--color-primary-green-rgb), 0.1);
  padding: 2px;
}

.columnTitle {
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0 30px;
}

.columnContent {
  width: 100%;
}

.card {
  margin: 12px 0;
  user-select: none;
  padding: 12px;
  cursor: grab;
}

.card[data-status="early"] {
  border-top: 3px solid var(--color-primary-green);
}

.card[data-status="normal"] {
  border-top: 3px solid var(--color-primary-orange);
}

.card[data-status="late"] {
  border-top: 3px solid var(--color-primary-red);
}

.card[data-stage="CLIENTE_CAPTADO"] {
  background-color: rgba(var(--color-primary-green-light-rgb), .4);
  border-top: none;
}

.card[data-stage="CLIENTE_PERDIDO"] {
  background-color: rgba(var(--color-primary-red-rgb), .25);
  border-top: none;
}

.card[data-stage="LEAD_NO_VALIDO"] {
  background-color: rgba(var(--color-primary-red-rgb), .45);
  border-top: none;
}

.cardTitle {
  color: var(--color-dark);
  text-decoration: none;
  padding-left: 1rem;
  font-weight: 500;
  display: block;
  margin-bottom: 1.2rem;
}

.cardTitle:hover {
  color: var(--color-primary-green);
  cursor: pointer;
}

.cardRow {
  display: flex;
  align-items: center;
  margin: .3rem 0;
  flex-wrap: wrap;
}

.cardRow[data-status="warning"] {
  color: var(--color-primary-orange);
}

.cardRow[data-status="danger"] {
  color: var(--color-primary-red);
}

.cardInfo {
  margin-left: 8px;
  flex-shrink: 0;
}

.placeholder {
  color: var(--color-gray);
  font-style: italic;
}

.fab {
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 100;
}