.stepper {
  margin: 1rem 5rem;
}

.input {
  margin-bottom: 30px;
}

.horario {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 2rem;
}

.dia {
  flex-basis: calc(50% - 20px);
}

.diaTitle {
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.horas {
  display: flex;
  margin-bottom: 3rem;
}

.horasBloque {
  display: flex;
  flex-direction: column;
  margin: 0 1.4rem;
}

.horasBloque:first-of-type {
  margin-left: 0;
}