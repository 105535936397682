.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 2rem 1.5rem;
  width: 100%;
}

.mes {
  max-width: 300px;
}