.label {
  display: flex;
  align-items: center;
}

.title {
  font-size: 1.2rem;
  font-weight: 500;
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 3rem 2rem 0;
}

.code {
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  margin: 0 5px;
}