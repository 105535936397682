.main {
  background: linear-gradient(135deg, #fff, var(--color-primary-green-light));
  min-height: 100%;
}

.content {
  padding: 10px 16px 50px;
}

.accordion {
  margin-top: 36px;
}