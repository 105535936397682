html > body {
  background-color: var(--color-dark);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  font-family: 'Roboto', sans-serif;
  -ms-overflow-style: none;
  scrollbar-width: none;

  --width-sidebar: 220px;

  --color-primary-green: #8cc63f;
  --color-primary-green-rgb: 140, 198, 63;
  
  --color-primary-green-light: #bdd736;
  --color-primary-green-light-rgb: 189, 215, 54;

  --color-primary-red: #f0513c;
  --color-primary-red-rgb: 240, 81, 60;

  --color-primary-orange: #f89f41;
  --color-primary-orange-rgb: 248, 159, 65;

  --color-secondary-green: #18ab50;
  --color-secondary-green-rgb: 24, 171, 80;

  --color-secondary-red: #ee2a3e;
  --color-secondary-red-rgb: 238, 42, 62;
  
  --color-secondary-orange: #ef5938;
  --color-secondary-orange-rgb: 239, 89, 56;

  --color-secondary-turquoise: #17ab7d;

  --color-secondary-yellow: #f4e41e;
  --color-secondary-yellow-rgb: 244, 228, 30;
  
  --color-auxiliary: #00bcd4;
  --color-auxiliary-rgb: 0, 188, 212;
  
  --color-gray: #8a8a8a;
  --color-gray-rgb: 138, 138, 138;
  
  --color-dark: #22262e;
  --color-dark-rgb: 34, 38, 46;

  --color-extra-dark: #050505;  
  --color-extra-dark-rgb: 5, 5, 5;

  --color-clase-recuperacion: #9400d3;
  --color-clase-recuperacion-rgb: 148, 0, 211;

  --color-clase-extra: #4682b4;
  --color-clase-extra-rgb: 70, 130, 180;

  --color-clase-cambio: #00bcd4;
  --color-clase-cambio-rgb: 0, 188, 212;

}

body[data-collapse="collapsed"] * {
  --width-sidebar: 0px;
}

::-webkit-scrollbar {
  display: none;
}