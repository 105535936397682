.main {
  display: flex;
  flex-direction: column;
}

.intro {
  margin-bottom: 1rem;
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.section {
  flex-basis: calc(50% - 50px);
  display: block;
}

.input {
  margin-bottom: 30px;
  width: 100%;
}

@media(max-width: 950px) {
  .content {
    flex-direction: column;
  }
  .section {
    flex-basis: 100%;
    width: 100%;
  }
}