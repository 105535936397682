.header {
  display: flex;
  align-items: center;
  padding: 0.6rem 1rem;
}

.headerIcon {
  background-color: var(--color-primary-green);
  color: white;
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}
