.row[data-open='open'] {
  background-color: rgba(var(--color-primary-green-rgb), .075);
}

.detailRow[data-open='open'] {
  background-color: rgba(var(--color-primary-green-rgb), .075);
}

.mainCell {
  border: 0;
}

.additionalRow {
  padding: 0;
}

.subtitle {
  margin: 1rem 1.5rem 6px;
  font-weight: bold;
}

.form {
  margin: 1rem auto 2rem;
  width: calc(100% - 60px);
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

.input {
  margin-bottom: 30px;
}

.detail {
  margin-bottom: 40px;
}

.button {
  margin: 0 0 0 auto;
}