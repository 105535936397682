.main {
  min-width: 120px;
}

.option {
  display: flex;
}

.option i {
  margin-right: 10px;
}