.actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.empty {
  margin: 2rem 4rem;
}

.download {
  margin-right: 40px;
}

.link {
  color: var(--color-dark);
  text-decoration: none;
}

.link:hover {
  color: var(--color-primary-green);
  cursor: pointer;
}

.placeholder {
  font-style: italic;
  color: var(--color-gray);
}