.actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.download {
  margin-right: 40px;
}

.link {
  color: var(--color-dark);
  text-decoration: none;
}

.link:hover {
  color: var(--color-primary-green);
  cursor: pointer;
}
