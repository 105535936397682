.main {
  background: linear-gradient(135deg, #fff, var(--color-primary-green-light));
  min-height: 100%;
}

.content {
  margin: 2rem auto 0;
  max-width: 600px;
  width: 90%;
  padding-bottom: 5rem;
}

.card {
  margin-bottom: 2rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.index {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: var(--color-primary-green);
  text-align: center;
  line-height: 24px;
  color: #fff;
  font-weight: bold;
}

.detailGroup {
  color: var(--color-gray);
  font-style: italic;
  margin-top: .5rem;
  display: flex;
  align-items: center;
}

.detailEdit {
  margin-left: 1rem;
}

.detail {
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.detail > i {
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.blockInfo {
  margin: .1rem 0 0.9rem;
  border-left: 3px solid rgba(var(--color-gray-rgb), .4);
  padding: 2px 0 2px .8rem;
}

.link {
  color: var(--color-dark);
  text-decoration: none;
}

.link:hover {
  color: var(--color-primary-green);
  cursor: pointer;
}


[data-confirmada="yes"] {
  color: var(--color-primary-green);
}

[data-confirmada="no"] {
  color: var(--color-primary-red);
}

[data-asistencia="VIENE"] {
  color: var(--color-primary-green);
}

[data-asistencia="FALTA_JUSTIFICADA"] {
  color: var(--color-primary-orange);
}

[data-asistencia="FALTA"] {
  color: var(--color-primary-red);
}

[data-asistencia="POR_DEFINIR"] {
  color: var(--color-gray);
}

.dialMenu {
  position: fixed; 
  bottom: 30px; 
  right: 120px; 
}

.speed {
  position: fixed;
  bottom: 30px; 
  right: 30px;
}