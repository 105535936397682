.intro {
  margin-bottom: 1rem;
}

.alert {
  margin: 1rem 0;
}

.input {
  margin-bottom: 30px;
}