.notification {
  z-index: 999999;
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  max-width: 90%;
  margin: 0 auto;
}

.alert {
  flex-grow: 1;
}
