.main {
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  align-items: center;
}

.title i {
  margin-right: 10px;
}

.intro {
  margin-bottom: 1rem;
}

.input {
  margin-bottom: 30px;
}