.main {
  display: flex;
  flex-direction: column;
}

.intro {
  margin-bottom: 1rem;
}

.selector {
  margin-bottom: 40px;
}

.radioGroup {
  justify-content: space-evenly;
}

.input {
  margin-bottom: 30px;
}

.alert {
  margin: 0 0 1rem;
}