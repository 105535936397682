.main {
  display: flex;
  flex-direction: column;
}

.intro {
  margin-bottom: 1rem;
}

.input {
  margin-bottom: 30px;
}

.documentLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 1rem;
}

.documentLink i {
  margin-right: 6px;
  font-size: 1.4rem;
}