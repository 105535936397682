.indicator {
  --size: 25px;
  display: inline-block;
  position: relative;
  width: var(--size);
  height: var(--size);
  margin-right: 10px;
}
.indicator div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: calc(0.8 * var(--size));
  height: calc(0.8 * var(--size));
  margin: calc(0.1 * var(--size));
  border: calc(0.1 * var(--size)) solid #fff;
  border-radius: 50%;
  animation: indicator 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.indicator div:nth-child(1) {
  animation-delay: -0.45s;
}
.indicator div:nth-child(2) {
  animation-delay: -0.3s;
}
.indicator div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes indicator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.number {
  position: relative;
  text-align: center;
  display: block;
  font-size: 10px;
  line-height: var(--size);
}