.label {
  display: flex;
  align-items: center;
}

.title {
  font-size: 1.2rem;
  font-weight: 500;
}

.button {
  margin-left: 10px;
}

.row {
  display: flex;
}

.field {
  margin: 10px 20px;
  flex-grow: 1;
}

@media(max-width: 1200px) {
  .row {
    flex-direction: column;
  }
  .field {
    margin: 10px 0 25px;
  }
}