.caja {
  display: flex;
  justify-content: space-around;
}

.cajaRow {
  padding: 0 2rem 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cajaCard {
  flex-basis: 40%;
  margin: 2rem;
}

.cajaActions {
  justify-content: flex-end;
  padding: .5rem 2rem;
}

.link {
  color: var(--color-dark);
  text-decoration: none;
}

.link:hover {
  color: var(--color-primary-green);
  cursor: pointer;
}

.createButton {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

@media(max-width: 600px) {
  .caja {
    flex-direction: column;
  }
}