.loading {
  padding: 1rem;
}

@media (max-width: 1500px) {
  .table [data-visibility='xl'] {
    display: none;
  }
}

@media (max-width: 1000px) {
  .table [data-visibility='lg'] {
    display: none;
  }
}

@media (max-width: 650px) {
  .table [data-visibility='md'] {
    display: none;
  }
}

@media (max-width: 450px) {
  .table [data-visibility='sm'] {
    display: none;
  }
}

