.section {
  margin: 2rem 1rem 2rem;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.summary {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chip {
  margin: 0 1rem;
}

.link {
  color: var(--color-dark);
  text-decoration: none;
}

.link:hover {
  color: var(--color-primary-green);
  cursor: pointer;
}

.deuda {
  color: var(--color-primary-red);
}

.deuda[data-status='pagado'] {
  color: var(--color-primary-green);
}