.main {
  display: flex;
  flex-direction: column;
}

.intro {
  margin-bottom: 1rem;
}

.input {
  margin-bottom: 30px;
}

.option {
  margin-bottom: 1rem;
}

.optionLabel {
  display: flex;
  flex-direction: column;
}

.optionDescription {
  font-style: italic;
  color: var(--color-gray);
  font-size: .9rem;
}