.main {
  display: flex;
  flex-direction: column;
}

.intro {
  margin-bottom: 1rem;
}

.section {
  display: flex;
  align-items: center;
}

.section .input {
  margin-left: 20px;
  margin-right: 20px;
}

.section .input:first-of-type {
  margin-left: 0;
}

.section .input:last-of-type {
  margin-right: 0;
}

.input {
  margin-bottom: 30px;
  flex-grow: 1;
}

.dateInput {
  margin-bottom: 16px;
}

@media(max-width: 950px) {
  .section {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .section .input {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}