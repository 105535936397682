.contrataciones {
  display: flex;
  margin: 1rem;
  align-items: flex-start;
  justify-content: space-between;
}

.listado {
  flex-shrink: 0;
  margin: 1rem;
  min-width: 420px;
}

.collapsed {
  padding-left: 1rem;
  padding-right: 1rem;
}

.contratacion {
  justify-content: space-between;
}

.contratacionIcon {
  margin-left: 1rem;
  min-width: 0;
}

.contratacionIcon[data-status='selected'] {
  color: var(--color-primary-green);
}

.detalle {
  margin: 1rem;
}

.centro {
  margin: 4px;
}

.descripcion {
  margin: 1rem 0;
}