.alert {
  margin-bottom: 1rem;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 10px 20px;
}
