.placeholder {
  font-style: italic;
  color: var(--color-gray);
}

.row[data-clase-fuera="true"] {
  background: rgba(var(--color-auxiliary-rgb), 0.2);
}

.row[data-alumno-fuera="true"] {
  background: rgba(var(--color-secondary-orange-rgb), 0.1);
}

.row[data-prueba="true"] {
  background: rgba(var(--color-primary-orange-rgb), 0.4);
}

.mainRowCell {
  border: 0;
}

.mainRowCell[data-visibility='invisible'] {
  display: none;
}

.alumnoLink {
  color: var(--color-dark);
  text-decoration: none;
}

.alumnoLink:hover {
  color: var(--color-primary-green);
  cursor: pointer;
}

.additionalRow {
  padding: 0;
}

.extraInfo {
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.extraInfo > i {
  margin-right: 0.5rem;
}

.profeInfo {
  padding: 0 1rem;
  display: flex;
  align-items: flex-end;
}

.profeInfo > i {
  margin-right: 0.5rem;
  padding: 5px 0;
}

.profeIntro {
  margin: 5px 1rem 5px 0;
}

.observaciones {
  padding: 2rem 4rem;
}