.presupuestador {
  display: flex;
  min-height: 100%;
}

.sidebar {
  padding: 30px 10px 10px;
  display: flex;
  flex-direction: column;
  width: 400px;
}

.sidebarCard {
  margin-bottom: 2rem;
}

.cardTitle {
  font-size: 1.1rem;
  text-decoration: underline;
}

.cardPackHoras {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 2rem 0 1rem;
}

.inputPack {
  flex-grow: 1;
}

.openPack {
  margin-left: 1rem;
}

.cardTarifa {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 1rem;
}


.inputTarifa {
  width: 100%;
  margin-bottom: 1.5rem;
}

.recomendacion {
  font-size: .5rem;
  margin: .2rem;
}

.cardTarifaButtons {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.cardTarifaButton {
  margin: .5rem;
  font-size: .8rem;
}

.cardTarifaButton:first-of-type {
  margin-left: 0;
}

.cardTarifaButton:last-of-type {
  margin-right: 0;
}

.calendar {
  padding: 10px;
  flex-grow: 1;
}

.monthSelection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 .5rem 0;
}

.calendarEvent {
  padding: 3px 4px;
  border-radius: 3px;
  cursor: pointer;
  margin: 3px;
  user-select: none;
}

.calendarEvent[data-color="primary-green"] {
  background-color: var(--color-primary-green);
}

.calendarEvent[data-color="primary-green-light"] {
  background-color: var(--color-primary-green-light);
}

.calendarEvent[data-color="primary-red"] {
  background-color: var(--color-primary-red);
}

.calendarEvent[data-color="primary-orange"] {
  background-color: var(--color-primary-orange);
}

.calendarEvent[data-color="secondary-green"] {
  background-color: var(--color-secondary-green);
}

.calendarEvent[data-color="secondary-red"] {
  background-color: var(--color-secondary-red);
}

.calendarEvent[data-color="secondary-orange"] {
  background-color: var(--color-secondary-orange);
}

.calendarEvent[data-color="secondary-turquoise"] {
  background-color: var(--color-secondary-turquoise);
}

.calendarEvent[data-color="secondary-yellow"] {
  background-color: var(--color-secondary-yellow);
}

.calendarEventNombre {
  margin: 0;
  font-weight: bold;
  font-size: .6rem;
}

.calendarEventNivel {
  margin: 0;
  font-size: .6rem;
}