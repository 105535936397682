.label {
  display: flex;
  align-items: center;
}

.title {
  font-size: 1.2rem;
  font-weight: 500;
}

.button {
  margin-left: 10px;
}

.details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: calc(50% - 20px);
}

.field {
  margin: 10px 20px;
  flex-grow: 1;
}

@media(max-width: 950px) {
  .column {
    flex-basis: 100%;
  }
  .field {
    margin: 10px 0 25px;
  }
}