.actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.chip {
  margin: 0 1rem;
}

.link {
  color: var(--color-dark);
  text-decoration: none;
}

.link:hover {
  color: var(--color-primary-green);
  cursor: pointer;
}