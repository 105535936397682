.link {
  color: var(--color-dark);
  text-decoration: none;
}

.link:hover {
  color: var(--color-primary-green);
  cursor: pointer;
}

.mes {
  width: 200px;
  margin: 20px;
}

.titulo {
  width: 900px;
  max-width: 900px;
}