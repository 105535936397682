.card {
  margin: 2rem auto 2rem 4rem;
  width: calc(100% - 6rem);
  max-width: 600px;
}

.placeholder {
  color: var(--color-gray);
  margin: 2rem;
  font-weight: 300;
}