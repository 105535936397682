.row[data-open='open'] {
  background-color: rgba(var(--color-primary-green-rgb), .075);
}

.detailRow[data-open='open'] {
  background-color: rgba(var(--color-primary-green-rgb), .075);
}

.mainCell {
  border: 0;
}

.additionalRow {
  padding: 0;
}

.estado[data-status='Pagado'] {
  color: var(--color-primary-green);
}

.estado[data-status='Pendiente'] {
  color: var(--color-primary-red);
}

.estado[data-status='Exceso'] {
  color: var(--color-primary-orange);
}

.link {
  color: var(--color-primary-green);
}

.placeholder {
  color: var(--color-gray);
  font-style: italic;
}

.subtitle {
  margin: 1rem 1.5rem 6px;
  font-weight: bold;
}

.ingresos {
  margin: 0rem 1rem 3rem;
}

.ingresosTable {
  border: 1px solid rgba(var(--color-gray-rgb), .3);
  border-bottom-width: 0px;
  background-color: white;
}

.promociones {
  margin: .5rem 1rem 2rem;
}

.promo {
  margin: 3px 5px;
}

.promocionNueva {
  margin: 1rem 0;
}