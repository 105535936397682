.actions {
  display: flex;
  align-items: flex-end;
}

.download {
  margin-right: 40px;
}

.fecha {
  margin-left: 40px;
}

.banner {
  margin: 2rem 2rem 1rem;
}

.link {
  color: var(--color-dark);
  text-decoration: none;
  display: block;
}

.link:hover {
  color: var(--color-primary-green);
  cursor: pointer;
}