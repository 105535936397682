.main {
  background: linear-gradient(135deg, #fff, var(--color-primary-green-light));
  min-height: 100%;
}
.formacion {
  padding: 10px 16px 50px;
}

.summary {
  display: flex;
}

.image {
  width: 250px;
  height: 200px;
  object-fit: cover;
  margin: 16px;
}

.content {
  padding: 16px;
}

.info {
  margin-top: 20px;
}

.academy,
.email,
.complete,
.ongoing {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.academy i,
.email i,
.complete i,
.ongoing i {
  margin-right: 8px;
}

.complete {
  color: var(--color-primary-green);
}

.ongoing {
  color: var(--color-primary-orange);
}

.academy strong {
  margin-left: 6px;
}

.ficha {
  margin-top: 12px;
}

.accordion {
  margin-top: 36px;
}
