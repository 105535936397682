.calendar {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--color-dark);
}

.column {
  border: 1px solid var(--color-dark);
  width: calc(100% / 7);
  text-align: center;
  font-size: 1rem;
}

.cell {
  border: 1px solid var(--color-dark);
  position: relative;
  padding: 24px 6px 12px 6px;
  height: 100px;
}

.cell:hover {
  background-color: rgba(var(--color-secondary-green-rgb), .05);
}

.cell[data-calendar-today="yes"] {
  background-color: rgba(var(--color-secondary-green-rgb), .2);
  
}

.date {
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 12px;
  font-weight: bold;
  user-select: none;
}

.cell[data-calendar-month="other"] {
  cursor: not-allowed;
}

.cell[data-calendar-month="other"] .date {
  opacity: .3;
}

.cell[data-calendar-today="yes"] .date {
  color: var(--color-secondary-green);
}

.cellContent {
  width: 100%;
  height: 100%;
}