.cerrar {
  width: 500px;
  max-width: calc(100% - 2rem);
  margin: 6rem auto 2rem;
  padding: 0rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.info {
  width: 100%;
  margin-bottom: 1rem;
}