.abrir {
  width: 500px;
  max-width: calc(100% - 2rem);
  margin: 6rem auto 2rem;
  padding: 1rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input {
  margin: 4rem 0;
  max-width: 300px;
}

.form {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.actions {
  justify-content: flex-end;
}