.intro {
  margin-bottom: 1.5rem;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.section {
  flex-basis: calc(50% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  margin-bottom: 30px;
  flex-grow: 1;
  width: 100%;
}

@media(max-width: 950px) {
  .content {
    flex-direction: column;
  }
}