.intro {
  margin-bottom: 20px;
}

.form {
  margin-top: 40px;
  margin-bottom: 20px;
}

.input {
  width: 100%;
}