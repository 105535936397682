.contratacion {
  margin: 2rem;
}

.placeholder {
  font-style: italic;
  color: var(--color-gray);
}

.bulk {
  margin-top: 1rem;
  padding: 0rem;
}

.bulkAction {
  margin: 1rem;
  font-size: .9rem;
}

.bulkAction i {
  font-size: 1.3rem;
  padding-right: 4px;
}

.content {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 100px;
}

.observaciones {
  width: 300px;
  max-width: 250px;
}

.title {
  margin: 4rem 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}