.intro {
  margin-bottom: 1.5rem;
}

.section {
  flex-basis: calc(50% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  margin-bottom: 30px;
  flex-grow: 1;
  width: 100%;
}