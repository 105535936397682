.header {
  position: fixed;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-dark);
  height: 50px;
}

.containerHero {
  display: flex;
  justify-content: space-between;
}

.headerCenter {
  color: #fff;
  display: inline-block;
  line-height: 50px;
  margin: 0 0 0 66px;
  vertical-align: top;
}

.name {
  display: inline-block;
  line-height: 50px;
  margin: 0 15px;
  vertical-align: middle;
}

.name a {
  text-decoration: none;
  color: var(--color-secondary-green);
}

.logo {
  height: 30px;
  margin: 10px;
  width: auto;
}

.image {
  height: 100%;
}

.actions {
  display: flex;
  align-items: center;
  color: white;
  margin-right: 20px;
}

.clock {
  margin-right: 20px;
}

.profile {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.button {
  padding: 6px;
  min-width: 40px;
  margin: 0 6px;
}

.mobileButton {
  display: none;
  padding: 6px;
  min-width: 40px;
  margin: 0 6px;
}

@media(max-width: 950px) {
  .clock {
    display: none;
  }
  .profile {
    display: none;
  }
  .name {
    display: none;
  }
  .mobileButton {
    display: inline-flex;
  }
}