.main {
  background: linear-gradient(135deg, #fff, var(--color-primary-green-light));
  min-height: 100%;
}

.mes {
  width: 200px;
  margin: 25px;
}

.content {
  margin: 25px 20px 0;
  padding-bottom: 40px;
}

.text {
  margin: 30px 25px;
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.item {
  margin: 10px;
  padding: 10px;
  max-width: 200px;
  cursor: pointer;
}

.date {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 0;
}

.date > i {
  font-size: 14px;
  margin-right: 6px;
}