.calendar {
  padding: 10px;
  flex-grow: 1;
}

.monthSelection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 .5rem 0;
}

.calendarEvent {
  border: 1px solid black;
  padding: 2px 3px;
  margin: 6px 0;
  border-radius: 3px;
  background-color: rgba(var(--color-extra-dark-rgb), .005);
  border-color: rgba(var(--color-extra-dark-rgb), .4);
}

.calendarEvent[data-status="dimmed"] {
  filter: blur(2px);
  opacity: .5;
}

.calendarEventRow {
  margin: 0;
  font-size: .65rem;
  line-height: .8rem;
  position: relative;
}

.calendarEventRow:first-of-type {
  margin-bottom: 4px;
}

.calendarEventAsistencia {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  position: absolute;
}

.calendarEventAsignatura {
  margin: 0;
}

.calendarEventDetail {
  color: var(--color-gray);
}

.speed {
  position: fixed;
  bottom: 30px; 
  right: 30px;
}