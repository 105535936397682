.block {
  margin: 2rem 1rem 7rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .8rem;
}

.actions {
  z-index: 1;
}

.actions > * {
  margin: 0 8px;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.placeholderContent {
  color: var(--color-gray);
}
